<template lang="pug">
  span
    app-dialog(v-model="showing" title="Unmatched emails")

      template(#dialog-action)
        slot(name="activator" :open="candidatesGetZoomPresentie")
          v-btn(
            color="primary"
            @click="candidatesGetZoomPresentie"
            :disabled="disabled"
            :loading="loading"
            outlined
            small
          ) Get Zoom Presentie

      template(#dialog-body)
        .unmatched_body
          .text-center
            span Updated
            span.text-primary  {{ updated_candidates }}
            span  candidate(s) presentie,
            span.text-error  {{ unmatched_emails.length || '0' }}
            span  email(s) not matched
          div(v-if="unmatched_emails" v-for="(email, index) in unmatched_emails" :key="index").emails-list
            span {{ email }}

      template(#dialog-footer)
        app-button(color="primary" @click.native="toggle") Close
</template>

<script>
import showingMixin from '@/mixins/showing.mixin'
import errorsMixin from '@/mixins/errors.mixin'
import apiCourses from '@/services/api/api-courses'
import courseService from '@/app/admin/models/courseService.js'

export default {
  mixins: [showingMixin, errorsMixin],

  props: {
    disabled: Boolean,
    courseID: Number
  },

  data: () => ({
    actionsService: new courseService(apiCourses),
    unmatched_emails: [],
    updated_candidates: 0,
    loading: false
  }),

  methods: {
    async candidatesGetZoomPresentie() {
      try {
        this.loading = true
        this.unmatched_emails = []
        let res = await this.actionsService.candidatesGetZoomPresentie(this.courseID)
        this.updateShowing(true)
        if (res && res.updated_candidates) this.updated_candidates = res.updated_candidates
        if (res && res.unmatched_emails) this.unmatched_emails = res.unmatched_emails
      } catch (err) {
        this.processError(err)
      } finally {
        this.loading = false
      }
    }
  },

  components: {
    appDialog: () => import('@/components/global/actions/BaseDialog.vue'),
    appButton: () => import('@/components/global/actions/BaseButton.vue')
  }
}
</script>

<style lang="scss" scoped>
.unmatched_body {
  padding: 10px 20px;
}

.emails-list {
  padding-top: 5px;
}
</style>
